import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItemText,
  Paper,
  Typography,
  LinearProgress,
  ListItemButton,
  Card,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Backdrop,
  ListItemIcon,
} from "@mui/material";
import MaijaContainer from "../../components/MaijaContainer";
import SkillsComponent from "./SkillsComponent";
import {
  fetchEducations,
  fetchLanguages,
  fetchSelfAssessment,
  fetchSkills,
  fetchSupportInfo,
  fetchWorkExperiences,
  updateProfilePicture,
} from "./ProfilePageRepository";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ProfilePageState, setProfilePageData } from "../../reducers/profilePageSlice";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import LanguagesComponent from "./LanguagesComponent";
import WorkExperiencesComponent from "./WorkExperienceComponent";
import EducationsComponent from "./EducationComponent";
import SupportInfoComponent from "./SupportInfoComponent";
import {
  CameraAlt,
  Home,
  Timeline,
  Work as WorkIcon,
  School as SchoolIcon,
  Info as InfoIcon,
  Build as BuildIcon,
  Translate as TranslateIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { RootState } from "../../store";
import { setProfilePictureUrl } from "../../reducers/userSlice";
import SupportInfoField, { AddressInfo, SupportInfoFieldType } from "./SupportInfoField";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import DisplayedValue from "../../components/DisplayedValue";
import SelfAssessmentComponent from "./SelfAssessmentComponent";
import {
  calculateCompletionPercentage,
  educationsCompletion,
  introductionCompletion,
  languagesCompletion,
  profileDataIsPrestineLoading,
  selfAssessmentCompletion,
  skillsCompletion,
  supportInfoCompletion,
  workExperiencesCompletion,
} from "./profileCompletionCalculations";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import IntroductionComponent from "./IntroductionComponent";
import CompletedProfileModal from "./CompletedProfileModal";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import MaijaExperienceTimeline from "../../components/MaijaExperienceTimeline";
import WcIcon from "@mui/icons-material/Wc";
import PlatsbankenJobsCountPaper from "./PlatsbankenJobsCountPaper";

const componentsMap: { [key: string]: React.FC } = {
  "Work experience": WorkExperiencesComponent,
  Education: EducationsComponent,
  Skills: SkillsComponent,
  Language: LanguagesComponent,
  "Work situation": SupportInfoComponent,
  "Self assessment": SelfAssessmentComponent,
  Introduction: IntroductionComponent,
};

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const [selectedComponent, setSelectedComponent] = useState<keyof typeof componentsMap>("Work experience");
  const SelectedComponent = componentsMap[selectedComponent];
  const workExperiences = useAppSelector((state: RootState) => state.profilePage.workExperiences);
  const educations = useAppSelector((state: RootState) => state.profilePage.educations);

  const loading = useFetchProfileData();
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [openProfileDoneModal, setOpenProfileDoneModal] = useState(false);
  const pageState = useAppSelector((state: RootState) => state.profilePage);
  const completionPercentageRef = useRef(completionPercentage);

  const tabs = [
    {
      key: "Work experience",
      icon: <WorkIcon />,
      translatedKey: t("profilePage.components.Work experience"),
    },
    {
      key: "Education",
      icon: <SchoolIcon />,
      translatedKey: t("profilePage.components.Education"),
    },
    {
      key: "Skills",
      icon: <BuildIcon />,
      translatedKey: t("profilePage.components.Skills"),
    },
    {
      key: "Language",
      icon: <TranslateIcon />,
      translatedKey: t("profilePage.components.Language"),
    },
    {
      key: "Work situation",
      icon: <InfoIcon />,
      translatedKey: t("profilePage.components.Work situation"),
    },
    {
      key: "Self assessment",
      icon: <QuestionAnswerIcon />,
      translatedKey: t("profilePage.components.Self assessment"),
    },
    {
      key: "Introduction",
      icon: <DescriptionIcon />,
      translatedKey: t("profilePage.components.Introduction"),
    },
  ];

  useEffect(() => {
    if (!loading) {
      const newCompletionPercentage = calculateCompletionPercentage(pageState);
      const oldCompletionPercentage = completionPercentageRef.current;

      setCompletionPercentage(newCompletionPercentage);
      completionPercentageRef.current = newCompletionPercentage;

      if (oldCompletionPercentage !== 100 && oldCompletionPercentage > 0 && newCompletionPercentage >= 100) {
        setOpenProfileDoneModal(true);
      }
    }
  }, [loading, pageState]);

  if (loading) {
    return <MaijaLoadingPage isFullscreen />;
  }

  return (
    <MaijaContainer sx={{ pb: 10, px: { xs: 2, sm: 4, md: 6 } }} maxWidth={false}>
      {completionPercentage < 100 && !loading && (
        <Box sx={{ width: "100%", mt: 10, mb: 5 }}>
          <LinearProgress variant="determinate" value={completionPercentage} color="primary" />
        </Box>
      )}
      {completionPercentage === 100 && <Box sx={{ width: "100%", mt: 10, mb: 5 }}></Box>}

      <ProfileCard />

      <PlatsbankenJobsCountPaper t={t} supportInfo={pageState.supportInfo} />
      <Paper sx={{ borderRadius: 6, mb: 5, p: 7 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Timeline sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)" }} />
          <Typography variant="h3">{t("profilePage.experienceTimeline.timeline")}</Typography>
        </Box>
        {workExperiences && educations && (
          <MaijaExperienceTimeline workExperiences={workExperiences} educations={educations} />
        )}
      </Paper>

      <Paper
        sx={{
          display: "flex",
          borderRadius: 6,
          flexDirection: { xs: "column", md: "row" },
          p: 2,
        }}
      >
        <List
          sx={{
            width: { xs: "100%", md: "230px" },
            display: { xs: "flex", md: "block" },
            flexDirection: { xs: "row", md: "column" },
            overflowX: { xs: "scroll", md: "visible" },
          }}
        >
          {tabs.map((tab) => {
            const isCompleted =
              (tab.key === "Work experience" &&
                pageState.workExperiences &&
                workExperiencesCompletion(pageState.workExperiences) >= 1) ||
              (tab.key === "Education" && pageState.educations && educationsCompletion(pageState.educations) >= 1) ||
              (tab.key === "Skills" && pageState.skills && skillsCompletion(pageState.skills) >= 1) ||
              (tab.key === "Language" && pageState.languages && languagesCompletion(pageState.languages) >= 1) ||
              (tab.key === "Work situation" &&
                pageState.supportInfo &&
                supportInfoCompletion(pageState.supportInfo) >= 1) ||
              (tab.key === "Introduction" &&
                pageState.supportInfo &&
                introductionCompletion(pageState.supportInfo) >= 1) ||
              (tab.key === "Self assessment" &&
                pageState.selfAssessmentQuestions &&
                selfAssessmentCompletion(pageState.selfAssessmentQuestions) >= 1);

            return (
              <ListItemButton
                selected={selectedComponent === tab.key}
                sx={{ m: 2, borderRadius: 6, flex: { xs: 1, md: "none" } }}
                key={tab.key}
                onClick={() => setSelectedComponent(tab.key as keyof typeof componentsMap)}
                data-cy={`profile-page-${tab.key}`}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>{tab.icon}</ListItemIcon>
                <ListItemText primary={tab.translatedKey} />
                {!loading && isCompleted && completionPercentage < 100 && (
                  <Fade in={true} timeout={400}>
                    <CheckCircleIcon style={{ fontSize: 30, color: green[500] }} />
                  </Fade>
                )}
              </ListItemButton>
            );
          })}
        </List>
        <Divider sx={{ my: { xs: 0, md: 2 } }} orientation="vertical" flexItem />
        <Box sx={{ flex: 1, p: 4 }}>
          <SelectedComponent />
        </Box>
      </Paper>
      <CompletedProfileModal isOpen={openProfileDoneModal} handleClose={() => setOpenProfileDoneModal(false)} />
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={profileDataIsPrestineLoading(pageState)}>
        <MaijaLoadingPage isFullscreen={true} />
      </Backdrop>
    </MaijaContainer>
  );
};

const ProfileCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profilePictureUrl = useAppSelector((state: RootState) => state.user.user?.profileIconUrl);
  const supportInfo = useAppSelector((state: RootState) => state.profilePage.supportInfo);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        try {
          const profileIconUrl = await updateProfilePicture(base64String);
          dispatch(setProfilePictureUrl(profileIconUrl));
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t("profilePage.supportInfo.saveError", {
                typeLabel: t("profilePage.supportInfo.profilePictureLabel"),
              }),
              severity: "error",
            }),
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Card sx={{ mb: 5, borderRadius: 6 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          p: 2,
          pb: 5,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Avatar sx={{ width: 170, height: 170, marginLeft: { xs: 0, md: 7 } }} src={profilePictureUrl || undefined}>
              {!profilePictureUrl && (
                <IconButton sx={{ width: 170, height: 170 }} component="label">
                  <CameraAlt />
                  <input type="file" hidden onChange={handleFileChange} />
                </IconButton>
              )}
            </Avatar>
            {profilePictureUrl && (
              <IconButton
                component="label"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  },
                }}
              >
                <CameraAlt />
                <input type="file" hidden onChange={handleFileChange} />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: { xs: 0, md: 7 },
            my: 4,
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4" sx={{ textAlign: { xs: "center", md: "left" } }}>
              {supportInfo?.name}
            </Typography>
          </Box>
          {supportInfo?.firstPreferredJob && (
            <Typography variant="subtitle2" sx={{ textAlign: { xs: "center", md: "left" } }}>
              {supportInfo.firstPreferredJob}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              flexGrow: 1,
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <SupportInfoField
                type={SupportInfoFieldType.Address}
                info={{
                  streetName: supportInfo?.streetName ?? "",
                  zipCode: supportInfo?.zipCode ?? "",
                  city: supportInfo?.city ?? "",
                }}
                getUpdatedSupportInfo={(value: AddressInfo) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        streetName: value.streetName,
                        zipCode: value.zipCode,
                        city: value.city,
                      }
                    : undefined
                }
                leadingSlot={<Home sx={{ mr: 4, fontSize: 25 }} />}
              />
              <SupportInfoField
                type={SupportInfoFieldType.PhoneNumber}
                info={supportInfo?.phoneNumber || ""}
                getUpdatedSupportInfo={(value: string) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        phoneNumber: value,
                      }
                    : undefined
                }
                leadingSlot={<LocalPhoneIcon sx={{ mr: 4, fontSize: 25 }} />}
              />
              <SupportInfoField
                type={SupportInfoFieldType.Gender}
                info={supportInfo?.gender || "OTHER"}
                getUpdatedSupportInfo={(value: string) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        gender: value,
                      }
                    : undefined
                }
                leadingSlot={<WcIcon sx={{ mr: 4, fontSize: 25 }} />}
              />
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Tooltip title={t("profilePage.supportInfo.emailTooltip")} arrow>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <EmailIcon sx={{ mr: 4, fontSize: 25 }} />
                    <Typography>{supportInfo?.email}</Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
            {supportInfo?.jobCoachName && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 200,
                  flexGrow: 5,
                  mr: 2,
                  mt: { xs: 2, md: 0 },
                }}
              >
                <Typography variant="h6">{t("profilePage.supportInfo.jobCoachLabel")}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Avatar
                    alt={supportInfo?.jobCoachName || undefined}
                    src={supportInfo?.jobCoachProfileIconUrl || undefined}
                    sx={{ width: 50, height: 50, mr: 1 }}
                  />
                  <Box
                    sx={{
                      ml: 2,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <DisplayedValue
                      name={t("profilePage.supportInfo.jobCoachNameLabel")}
                      value={supportInfo?.jobCoachName}
                    />
                    <DisplayedValue
                      name={t("profilePage.supportInfo.jobCoachPhoneLabel")}
                      value={supportInfo?.jobCoachPhoneNumber}
                    />
                    <DisplayedValue
                      name={t("profilePage.supportInfo.jobCoachEmailLabel")}
                      value={supportInfo?.jobCoachEmail}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const useFetchProfileData = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [workExperiences, educations, skills, languages, supportInfo, selfAssessmentQuestions] =
          await Promise.all([
            fetchWorkExperiences(),
            fetchEducations(),
            fetchSkills(),
            fetchLanguages(),
            fetchSupportInfo(),
            fetchSelfAssessment(),
          ]);

        const sortedWorkExperiences = workExperiences.sort(
          (a, b) => new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime(),
        );
        const sortedEducations = educations.sort(
          (a, b) => new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime(),
        );

        const profileData: ProfilePageState = {
          workExperiences: sortedWorkExperiences,
          educations: sortedEducations,
          skills,
          languages,
          supportInfo,
          selfAssessmentQuestions: selfAssessmentQuestions,
        };

        dispatch(setProfilePageData(profileData));
      } catch (error) {
        dispatch(
          showSnackbar({
            message: t("profilePage.supportInfo.loadError"),
            severity: "error",
          }),
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, t]);

  return loading;
};

export default ProfilePage;
