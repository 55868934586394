import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { Application } from "../types/Application";

export interface ApplicationDetails {
  application: Application;
  pendingApplication?: Application;
  resumeThumbnail?: string;
  coverLetterThumbnail?: string;
  openedFromApplicationsList: boolean;
}

interface ApplicationDetailsPageState {
  applicationsMap: { [id: string]: ApplicationDetails };
}

const initialState: ApplicationDetailsPageState = {
  applicationsMap: {},
};

export const applicationDetailsPageSlice = createSlice({
  name: "applicationDetails",
  initialState,
  reducers: {
    setApplicationDetailsFromApplication: (
      state,
      action: PayloadAction<{
        application: Application;
        openedFromApplicationsList: boolean;
      }>,
    ) => {
      const { application, openedFromApplicationsList } = action.payload;
      const currentApplicationDetails = state.applicationsMap[application.id];

      state.applicationsMap = {
        ...state.applicationsMap,
        [application.id]: {
          ...(currentApplicationDetails || {}),
          application,
          openedFromApplicationsList,
        },
      };
    },
    setApplicationDetails: (state, action: PayloadAction<ApplicationDetails>) => {
      const { application } = action.payload;
      if (application && application.id) {
        state.applicationsMap = {
          ...state.applicationsMap,
          [application.id]: action.payload,
        };
      } else {
        console.warn("Invalid application details received:", action.payload);
      }
    },
    updateApplication: (state, action: PayloadAction<Application>) => {
      const applicationId = action.payload.id;
      if (state.applicationsMap[applicationId]) {
        state.applicationsMap = {
          ...state.applicationsMap,
          [applicationId]: {
            ...state.applicationsMap[applicationId],
            application: action.payload,
          },
        };
      }
    },
    updateResumeThumbnail: (
      state,
      action: PayloadAction<{
        applicationId: string;
        resumeThumbnail: string;
      }>,
    ) => {
      const { applicationId, resumeThumbnail } = action.payload;
      if (state.applicationsMap[applicationId]) {
        state.applicationsMap = {
          ...state.applicationsMap,
          [applicationId]: {
            ...state.applicationsMap[applicationId],
            resumeThumbnail,
          },
        };
      }
    },
    updateCoverLetterThumbnail: (
      state,
      action: PayloadAction<{
        applicationId: string;
        coverLetterThumbnail: string;
      }>,
    ) => {
      const { applicationId, coverLetterThumbnail } = action.payload;
      if (state.applicationsMap[applicationId]) {
        state.applicationsMap = {
          ...state.applicationsMap,
          [applicationId]: {
            ...state.applicationsMap[applicationId],
            coverLetterThumbnail,
          },
        };
      }
    },
    setPendingApplication: (
      state,
      action: PayloadAction<{
        applicationId: string;
        pendingApplication: Application | undefined;
      }>,
    ) => {
      const { applicationId, pendingApplication } = action.payload;
      if (state.applicationsMap[applicationId]) {
        state.applicationsMap = {
          ...state.applicationsMap,
          [applicationId]: {
            ...state.applicationsMap[applicationId],
            pendingApplication,
          },
        };
      } else {
        console.error(`No application found with ID ${applicationId}`);
      }
    },
    setApplicationFromPending: (state, action: PayloadAction<string>) => {
      const applicationId = action.payload;
      const applicationDetails = state.applicationsMap[applicationId];
      if (applicationDetails && applicationDetails.pendingApplication) {
        state.applicationsMap = {
          ...state.applicationsMap,
          [applicationId]: {
            ...applicationDetails,
            application: applicationDetails.pendingApplication,
            pendingApplication: undefined,
          },
        };
      } else {
        console.error(`No pending application found with ID ${applicationId}`);
      }
    },
    clearApplication: (state, action: PayloadAction<string>) => {
      const { [action.payload]: _, ...remainingApps } = state.applicationsMap;
      state.applicationsMap = remainingApps;
    },
    clearApplicationDetailsPage: (state) => {
      state.applicationsMap = {};
    },
  },
});

export const {
  setApplicationDetailsFromApplication,
  updateApplication,
  updateResumeThumbnail,
  updateCoverLetterThumbnail,
  setApplicationDetails,
  setPendingApplication,
  setApplicationFromPending,
  clearApplication,
  clearApplicationDetailsPage,
} = applicationDetailsPageSlice.actions;
export const applicationDetailsPage = (state: RootState) => state.applicationDetailsPage;
export default applicationDetailsPageSlice.reducer;
