import React from "react";
import { Box, Typography, CardProps } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Application } from "../../../types/Application";
import { formatDateToString } from "../../../utils/DateUtils";
import appliedImage from "../../../assets/drawable/applied_image.png";
import notAppliedImage from "../../../assets/drawable/not_applied_image.png";

export interface ApplicationSummaryModuleProps extends CardProps {
  application: Application;
  maxWidth: number;
}

export const ApplicationSummaryModule: React.FC<ApplicationSummaryModuleProps> = ({
  application,
  maxWidth,
  ...cardProps
}) => {
  const { t } = useTranslation();
  const appliedDate = application.appliedDate;

  const renderContent = () => {
    let body = "";

    if (application.hasApplied) {
      body = t("applicationDetails.page.applicationSentBody", {
        workplace: application.workplace,
        role: application.role,
      });
    } else {
      body = t("applicationDetails.page.applicationReadyBody", {
        workplace: application.workplace,
        role: application.role,
      });
    }

    return (
      <>
        <Box sx={{ textAlign: "center" }}>
          <img
            src={application.hasApplied ? appliedImage : notAppliedImage}
            alt={application.hasApplied ? "Applied" : "Not Applied"}
            style={{
              width: application.hasApplied ? 200 : 250,
              height: "auto",
            }}
          />
        </Box>
        <Typography sx={{ textAlign: "center" }} variant="subtitle1">
          <Trans
            i18nKey={body}
            values={{ role: application.role, workplace: application.workplace }}
            components={{ strong: <strong /> }}
          />
        </Typography>
        {appliedDate && (
          <Typography sx={{ textAlign: "center", mt: 4 }} variant="body2" color="textSecondary">
            {t("applicationDetails.page.appliedAt", { date: formatDateToString(appliedDate) })}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        mb: 6,
        maxWidth,
        ...cardProps.sx,
      }}
    >
      {renderContent()}
    </Box>
  );
};
