import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
  InputAdornment,
  IconButton,
  Collapse,
} from "@mui/material";
import { Visibility, VisibilityOff, CheckCircleOutline } from "@mui/icons-material";
import { changePassword } from "./ChangePasswordRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import MaijaContainer from "../../components/MaijaContainer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ChangePasswordPage: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [isOldPasswordFocused, setIsOldPasswordFocused] = useState<boolean>(false);
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState<boolean>(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validatePasswords = () => {
    const newErrors: { [key: string]: string } = {};

    if (!oldPassword) {
      newErrors.oldPassword = t("changePassword.errorOldPasswordRequired");
    }

    if (!newPassword) {
      newErrors.newPassword = t("changePassword.errorNewPasswordRequired");
    } else if (newPassword.length < 8) {
      newErrors.newPassword = t("changePassword.errorNewPasswordTooShort");
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = t("changePassword.errorConfirmPasswordRequired");
    } else if (newPassword !== confirmPassword) {
      newErrors.newPassword = t("changePassword.errorPasswordsDoNotMatch");
      newErrors.confirmPassword = t("changePassword.errorPasswordsDoNotMatch");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
  };
  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async () => {
    if (!validatePasswords()) {
      return;
    }

    setIsLoading(true);

    try {
      await changePassword(oldPassword, newPassword);
      setSuccess(true);

      dispatch(
        showSnackbar({
          message: t("changePassword.passwordUpdated"),
          severity: "success",
        }),
      );

      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");

      setTimeout(() => {
        setSuccess(false);
        navigate("/profile");
      }, 2000);
    } catch (error) {
      console.error(error);
      dispatch(
        showSnackbar({
          message: t("changePassword.errorUpdating"),
          severity: "error",
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MaijaContainer
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ mt: 10, width: "100%", maxWidth: 500 }}>
        <CardContent>
          <Container component="main">
            <Collapse in={!success}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="h1" variant="h5">
                  {t("changePassword.title")}
                </Typography>

                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    sx={{ mt: 3 }}
                    label={t("changePassword.currentPasswordLabel")}
                    autoComplete="current-password"
                    type={showOldPassword ? "text" : "password"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword}
                    data-cy="current-password"
                    onFocus={() => setIsOldPasswordFocused(true)}
                    onBlur={() => setIsOldPasswordFocused(false)}
                    InputProps={{
                      endAdornment: isOldPasswordFocused ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowOldPassword(!showOldPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    label={t("changePassword.newPasswordLabel")}
                    autoComplete="new-password"
                    type={showNewPassword ? "text" : "password"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    data-cy="new-password"
                    onFocus={() => setIsNewPasswordFocused(true)}
                    onBlur={() => setIsNewPasswordFocused(false)}
                    InputProps={{
                      endAdornment: isNewPasswordFocused ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    label={t("changePassword.confirmPasswordLabel")}
                    autoComplete="new-password"
                    type={showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    data-cy="confirm-password"
                    onFocus={() => setIsConfirmPasswordFocused(true)}
                    onBlur={() => setIsConfirmPasswordFocused(false)}
                    InputProps={{
                      endAdornment: isConfirmPasswordFocused ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    onClick={handleSubmit}
                    data-cy="submit"
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : t("changePassword.submitButton")}
                  </Button>
                </Box>
              </Box>
            </Collapse>

            <Collapse in={success}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <CheckCircleOutline style={{ fontSize: 80, color: "green" }} />
                <Typography variant="h5" sx={{ mt: 2 }}>
                  {t("changePassword.passwordUpdated")}
                </Typography>
              </Box>
            </Collapse>
          </Container>
        </CardContent>
      </Card>
    </MaijaContainer>
  );
};

export default ChangePasswordPage;
