import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { getMaijaRoutes, LOGIN_ROUTE, FORGOT_PASSWORD_ROUTE, RESET_PASSWORD_ROUTE, PROFILE_ROUTE } from "./Routes";
import LoginPage from "./pages/login/LoginPage";
import MaijaSnackbar from "./components/MaijaSnackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./i18n";
import CustomThemeProvider from "./providers/CustomThemeProvider";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "./pages/reset-password/ResetPasswordPage";
import { AuthProvider, AuthContext } from "./providers/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import MaijaLoadingPage from "./components/MaijaLoadingPage";
import PublicRoute from "./components/PublicRoute";

function App() {
  const maijaRoutes = getMaijaRoutes();

  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ isAuthenticated, loading }) => {
          if (loading) {
            return <MaijaLoadingPage isFullscreen />;
          }

          return (
            <CustomThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                <Router>
                  <Routes>
                    <Route
                      path={LOGIN_ROUTE.path}
                      element={
                        <PublicRoute>
                          <LoginPage />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path={FORGOT_PASSWORD_ROUTE.path}
                      element={
                        <PublicRoute>
                          <ForgotPasswordPage />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path={RESET_PASSWORD_ROUTE.path}
                      element={
                        <PublicRoute>
                          <ResetPasswordPage />
                        </PublicRoute>
                      }
                    />

                    {isAuthenticated &&
                      maijaRoutes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          element={
                            <ProtectedRoute requireApplicationDetails={route.requireApplicationDetails}>
                              {React.createElement(route.component)}
                            </ProtectedRoute>
                          }
                        />
                      ))}

                    <Route
                      path="*"
                      element={
                        isAuthenticated ? (
                          <Navigate to={PROFILE_ROUTE.path} replace />
                        ) : (
                          <Navigate to={LOGIN_ROUTE.path} replace />
                        )
                      }
                    />
                  </Routes>
                  <MaijaSnackbar />
                </Router>
              </LocalizationProvider>
            </CustomThemeProvider>
          );
        }}
      </AuthContext.Consumer>
    </AuthProvider>
  );
}

export default App;
