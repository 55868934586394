import React, { useState, useEffect } from "react";
import { Box, Modal, Typography, TextField, Button, ToggleButton, ToggleButtonGroup, Chip } from "@mui/material";
import ApplicationTone from "../../../types/ApplicationTone";
import ApplicationTemplateStyle from "../../../types/ApplicationTemplateStyle";
import ApplicationLanguage from "../../../types/ApplicationLanguage";
import { useTranslation } from "react-i18next";

interface CombinedEditModalProps {
  isOpen: boolean;
  initialContent: string;
  currentLanguage: ApplicationLanguage;
  currentTemplate: ApplicationTemplateStyle;
  currentTones: ApplicationTone[];
  onSave: (
    content: string,
    language: ApplicationLanguage,
    template: ApplicationTemplateStyle,
    tone: ApplicationTone[],
  ) => Promise<void> | void;
  onClose: () => void;
}

const tones = [
  ApplicationTone.Casual,
  ApplicationTone.Formal,
  ApplicationTone.Professional,
  ApplicationTone.Friendly,
  ApplicationTone.Humorous,
  ApplicationTone.Direct,
];

const CombinedEditModal: React.FC<CombinedEditModalProps> = ({
  isOpen,
  initialContent,
  currentLanguage,
  currentTemplate,
  currentTones,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<"edit" | "settings">("edit");
  const [content, setContent] = useState(initialContent);
  const [language, setLanguage] = useState<ApplicationLanguage>(currentLanguage);
  const [template, setTemplate] = useState<ApplicationTemplateStyle>(currentTemplate);
  const [selectedTones, setSelectedTones] = useState<ApplicationTone[]>(currentTones);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setContent(initialContent);
      setLanguage(currentLanguage);
      setTemplate(currentTemplate);
      setSelectedTones(currentTones);
      setViewMode("edit");
    }
  }, [isOpen, initialContent, currentLanguage, currentTemplate, currentTones]);

  const handleToggleView = (event: React.MouseEvent<HTMLElement>, mode: "edit" | "settings" | null) => {
    if (mode) {
      setViewMode(mode);
    }
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      await onSave(content, language, template, selectedTones);
    } catch (error) {
      console.error("Error saving changes:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleToneClick = (tone: ApplicationTone) => {
    if (selectedTones.includes(tone)) {
      setSelectedTones(selectedTones.filter((t) => t !== tone));
    } else if (selectedTones.length < 3) {
      setSelectedTones([...selectedTones, tone]);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: `calc(50% + ${210 / 2}px)`,
          transform: "translateX(-50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "90%",
          maxWidth: 600,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {t("applicationDetails.coverLetterPreviewModal.editTitle")}
        </Typography>

        <ToggleButtonGroup value={viewMode} exclusive onChange={handleToggleView} sx={{ mb: 2 }}>
          <ToggleButton value="edit">{t("applicationDetails.coverLetterPreviewModal.editText")}</ToggleButton>
          <ToggleButton value="settings">
            {t("applicationDetails.coverLetterPreviewModal.languageAndTone")}
          </ToggleButton>
        </ToggleButtonGroup>

        {viewMode === "edit" ? (
          <TextField
            label={t("applicationDetails.coverLetterPreviewModal.editLabel")}
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ mb: 3 }}
          />
        ) : (
          <Box>
            <Typography>{t("applicationDetails.page.changeApplicationTone")}</Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 2 }}>
              {tones.map((tone) => (
                <Chip
                  key={tone}
                  label={t(`applicationDetails.page.tones.${tone}`)}
                  clickable
                  color={selectedTones.includes(tone) ? "secondary" : "default"}
                  onClick={() => handleToneClick(tone)}
                />
              ))}
            </Box>
            <Typography variant="caption" color="textSecondary">
              {t("applicationDetails.page.toneLimit")}
            </Typography>
          </Box>
        )}

        <Button
          onClick={handleSaveChanges}
          color="primary"
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          disabled={isSaving}
        >
          {isSaving
            ? t("applicationDetails.coverLetterPreviewModal.saving")
            : t("applicationDetails.coverLetterPreviewModal.saveButton")}
        </Button>
      </Box>
    </Modal>
  );
};

export default CombinedEditModal;
