import React, { ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { PROFILE_ROUTE } from "../Routes";

interface PublicRouteProps {
  children: ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Navigate to={PROFILE_ROUTE.path} replace />;
  }

  return children;
};

export default PublicRoute;
