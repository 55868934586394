import { Box, Skeleton, Paper, IconButton, styled, Typography, Tooltip } from "@mui/material";
import { GetApp, Email, Close, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import MaijaModal from "../../../components/MaijaModal";
import { getCoverLetterPreview, downloadApplication, emailApplication } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { downloadBase64File } from "../../../utils/FileDownloadUtil";

interface CoverLetterPreviewModalProps {
  isOpen: boolean;
  coverLetterId: string;
  applicationId: string;
  hasApplied: boolean;
  handleClose: () => void;
  onEditCoverLetter: () => void;
  refreshKey: number;
}

const calculateWidthFromHeight = (height: number) => height / 1.414;

const A4Paper = styled(Paper)(() => ({
  height: "120vh",
  width: `${calculateWidthFromHeight(window.innerHeight * 1.2)}px`,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  borderRadius: 2,
  position: "relative",
  marginBottom: 16,
}));

const CoverLetterPreviewModal: React.FC<CoverLetterPreviewModalProps> = ({
  isOpen,
  coverLetterId,
  applicationId,
  hasApplied,
  handleClose,
  onEditCoverLetter,
  refreshKey,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnails, setThumbnails] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getCoverLetterPreview(coverLetterId)
        .then((response) => setThumbnails(response.thumbnails))
        .catch(() =>
          dispatch(
            showSnackbar({ message: t("applicationDetails.coverLetterPreviewModal.errorLoading"), severity: "error" }),
          ),
        )
        .finally(() => setIsLoading(false));
    }
  }, [isOpen, refreshKey]);

  const handleDownload = () => {
    downloadApplication(applicationId)
      .then((data) => data && downloadBase64File(data.coverLetter, `Cover_Letter_${applicationId}`))
      .catch(() =>
        dispatch(showSnackbar({ message: t("applicationDetails.page.downloadingError"), severity: "error" })),
      );
  };

  const handleEmail = () => {
    emailApplication(applicationId).catch(() =>
      dispatch(showSnackbar({ message: t("applicationDetails.page.emailingError"), severity: "error" })),
    );
  };

  return (
    <MaijaModal
      isOpen={isOpen}
      handleClose={handleClose}
      width={calculateWidthFromHeight(window.innerHeight * 0.8).toString()}
      maxHeight="95vh"
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", mb: 2 }}>
          <Typography variant="h6">{t("applicationDetails.page.coverLetter")}</Typography>
          <Box>
            {!hasApplied && (
              <Tooltip title={t("applicationDetails.page.editTooltip")}>
                <IconButton onClick={onEditCoverLetter}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t("applicationDetails.page.downloadTooltip")}>
              <IconButton onClick={handleDownload}>
                <GetApp />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("applicationDetails.page.emailTooltip")}>
              <IconButton onClick={handleEmail}>
                <Email />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {isLoading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            width={calculateWidthFromHeight(window.innerHeight * 1.2)}
            height="120vh"
          />
        ) : (
          thumbnails.map((thumbnail, index) => (
            <A4Paper key={index}>
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  gap: 1,
                  zIndex: 10,
                }}
              >
                <Tooltip title={t("applicationDetails.page.closeTooltip")}>
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                      padding: 0.5,
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Box>

              <img
                src={`data:image/jpeg;base64,${thumbnail}`}
                alt={`Cover Letter Page ${index + 1}`}
                style={{ width: "100%", height: "100%", borderRadius: "8px" }}
              />
            </A4Paper>
          ))
        )}
      </Box>
    </MaijaModal>
  );
};

export default CoverLetterPreviewModal;
