const allLanguages = [
  { name: "Afrikaans", code: "af" },
  { name: "Albanska", code: "sq" },
  { name: "Amhariska", code: "am" },
  { name: "Arabiska", code: "ar" },
  { name: "Armeniska", code: "hy" },
  { name: "Asturiska", code: "ast" },
  { name: "Baskiska", code: "eu" },
  { name: "Bengali", code: "bn" },
  { name: "Bosniska", code: "bs" },
  { name: "Bretonska", code: "br" },
  { name: "Burmesiska", code: "my" },
  { name: "Chichewa", code: "ny" },
  { name: "Danska", code: "da" },
  { name: "Engelska", code: "en" },
  { name: "Esperanto", code: "eo" },
  { name: "Estniska", code: "et" },
  { name: "Filippinska", code: "tl" },
  { name: "Finska", code: "fi" },
  { name: "Franska", code: "fr" },
  { name: "Frisian", code: "fy" },
  { name: "Galiciska", code: "gl" },
  { name: "Georgiska", code: "ka" },
  { name: "Grekiska", code: "el" },
  { name: "Gujarati", code: "gu" },
  { name: "Haitisk kreol", code: "ht" },
  { name: "Hausa", code: "ha" },
  { name: "Hawaiiska", code: "haw" },
  { name: "Hebreiska", code: "he" },
  { name: "Hindi", code: "hi" },
  { name: "Igbo", code: "ig" },
  { name: "Indonesiska", code: "id" },
  { name: "Iriska", code: "ga" },
  { name: "Isländska", code: "is" },
  { name: "Italienska", code: "it" },
  { name: "Japanska", code: "ja" },
  { name: "Kannada", code: "kn" },
  { name: "Kantonesiska", code: "yue" },
  { name: "Kazakhiska", code: "kk" },
  { name: "Kinesiska", code: "zh" },
  { name: "Kinyarwanda", code: "rw" },
  { name: "Kirundi", code: "rn" },
  { name: "Koreanska", code: "ko" },
  { name: "Korsikanska", code: "co" },
  { name: "Kroatiska", code: "hr" },
  { name: "Kurdiska", code: "ku" },
  { name: "Lao", code: "lo" },
  { name: "Lettiska", code: "lv" },
  { name: "Litauiska", code: "lt" },
  { name: "Luxemburgiska", code: "lb" },
  { name: "Malajalam", code: "ml" },
  { name: "Malajiska", code: "ms" },
  { name: "Maltesiska", code: "mt" },
  { name: "Mandarinkinesiska", code: "cmn" },
  { name: "Maori", code: "mi" },
  { name: "Marathi", code: "mr" },
  { name: "Mongoliska", code: "mn" },
  { name: "Nederländska", code: "nl" },
  { name: "Nepali", code: "ne" },
  { name: "Norska", code: "no" },
  { name: "Occitanska", code: "oc" },
  { name: "Pashto", code: "ps" },
  { name: "Persiska", code: "fa" },
  { name: "Polska", code: "pl" },
  { name: "Portugisiska", code: "pt" },
  { name: "Punjabi", code: "pa" },
  { name: "Rumänska", code: "ro" },
  { name: "Ryska", code: "ru" },
  { name: "Samoanska", code: "sm" },
  { name: "Serbiska", code: "sr" },
  { name: "Singalesiska", code: "si" },
  { name: "Sinhala", code: "si" },
  { name: "Skotsk gaeliska", code: "gd" },
  { name: "Slovakiska", code: "sk" },
  { name: "Slovenska", code: "sl" },
  { name: "Somaliska", code: "so" },
  { name: "Spanska", code: "es" },
  { name: "Svenska", code: "sv" },
  { name: "Swahili", code: "sw" },
  { name: "Tadzjikiska", code: "tg" },
  { name: "Tagalog", code: "tl" },
  { name: "Tamil", code: "ta" },
  { name: "Telugu", code: "te" },
  { name: "Thailändska", code: "th" },
  { name: "Tigrinja", code: "ti" },
  { name: "Tjeckiska", code: "cs" },
  { name: "Turkiska", code: "tr" },
  { name: "Turkmeniska", code: "tk" },
  { name: "Tyska", code: "de" },
  { name: "Ungerska", code: "hu" },
  { name: "Urdu", code: "ur" },
  { name: "Uzbekiska", code: "uz" },
  { name: "Vietnamesiska", code: "vi" },
  { name: "Walesiska", code: "cy" },
  { name: "Wolof", code: "wo" },
  { name: "Yoruba", code: "yo" },
  { name: "Zulu", code: "zu" },
];

export default allLanguages;
