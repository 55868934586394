import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { performLogout } from "./LogoutUtil";
import { useAppDispatch } from "../hooks";
import { clearLoggedInUser } from "../reducers/userSlice";
import { clearApplicationPage } from "../reducers/applicationPageSlice";
import { clearProfilePage } from "../reducers/profilePageSlice";
import { clearApplicationDetailsPage } from "../reducers/applicationDetailsPageSlice";
import { clearApplicationsListPage } from "../reducers/applicationsListPageSlice";

export function useLogout() {
  const { setUser, setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = async () => {
    await performLogout();

    setUser(null);
    setIsAuthenticated(false);

    localStorage.removeItem("currentUser");

    dispatch(clearLoggedInUser());
    dispatch(clearProfilePage());
    dispatch(clearApplicationPage());
    dispatch(clearApplicationDetailsPage());
    dispatch(clearApplicationsListPage());

    navigate("/login", { replace: true });
  };

  return logout;
}
