import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { RootState } from "../../store";
import { Delete, Edit } from "@mui/icons-material";
import { Language } from "../../types/Language";
import LanguageModal from "./LanguageModal";
import { deleteLanguage as apiDeleteLanguage } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { deleteLanguage } from "../../reducers/profilePageSlice";
import { useTranslation } from "react-i18next";
import { PROFICIENCY_LEVELS } from "../../types/Language";

const LanguagesComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const languages = useSelector((state: RootState) => state.profilePage.languages);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [languageToDelete, setLanguageToDelete] = useState<Language | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleAddClick = () => {
    setCurrentLanguage(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (language: Language) => {
    setCurrentLanguage(language);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (language: Language) => {
    setLanguageToDelete(language);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (languageToDelete) {
      setIsDeleting(true);
      try {
        await apiDeleteLanguage(languageToDelete.id);
        dispatch(deleteLanguage(languageToDelete.id));
        dispatch(showSnackbar({ message: t("profilePage.language.deleteSuccess"), severity: "success" }));
      } catch (error) {
        dispatch(showSnackbar({ message: t("profilePage.language.deleteError"), severity: "error" }));
      } finally {
        setIsDeleting(false);
        setIsDeleteDialogOpen(false);
        setLanguageToDelete(null);
      }
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {languages && languages.length > 0 ? (
        <LanguagesList
          languages={languages}
          onAdd={handleAddClick}
          onEdit={handleEditClick}
          onDelete={handleDeleteClick}
        />
      ) : (
        <EmptyState onAdd={handleAddClick} />
      )}
      <LanguageModal open={isModalOpen} onClose={() => setIsModalOpen(false)} initialLanguage={currentLanguage} />
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>{t("profilePage.language.confirmDeleteTitle")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("profilePage.language.confirmDeleteMessage", {
              language: t(`languages.${languageToDelete?.code}`),
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            {t("generic.cancel")}
          </Button>
          <Button onClick={confirmDelete} color="secondary" variant="contained" disabled={isDeleting}>
            {t("generic.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

interface LanguagesListProps {
  languages: Language[];
  onAdd: () => void;
  onEdit: (language: Language) => void;
  onDelete: (language: Language) => void;
}

const LanguagesList: React.FC<LanguagesListProps> = ({ languages, onAdd, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const proficiencyLabels = PROFICIENCY_LEVELS.reduce(
    (acc, level) => {
      acc[level.value] = level.labelKey ? t(level.labelKey) : level.label || "";
      return acc;
    },
    {} as Record<string, string>,
  );

  const groupedLanguages = languages.reduce(
    (acc, language) => {
      const proficiency = (language.proficiency || "MISSING").toUpperCase();
      if (!acc[proficiency]) {
        acc[proficiency] = [];
      }
      acc[proficiency].push(language);
      return acc;
    },
    {} as Record<string, Language[]>,
  );

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>
        {t("profilePage.language.title")}
      </Typography>
      <Box sx={{ flex: 1 }}>
        {PROFICIENCY_LEVELS.map((proficiency) => {
          const languagesForProficiency = groupedLanguages[proficiency.value];
          if (languagesForProficiency && languagesForProficiency.length > 0) {
            return (
              <Box key={proficiency.value} sx={{ mt: 4 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", textAlign: "left" }}>
                  {proficiencyLabels[proficiency.value]}
                </Typography>
                <List>
                  {languagesForProficiency.map((language) => (
                    <ListItem
                      key={language.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 0.5,
                        pl: 0,
                      }}
                    >
                      <ListItemText primary={t(`languages.${language.code}`)} />
                      <Box>
                        <IconButton edge="end" aria-label="edit" sx={{ mr: 1 }} onClick={() => onEdit(language)}>
                          <Edit />
                        </IconButton>
                        {language.code !== "sv" && (
                          <IconButton edge="end" aria-label="delete" onClick={() => onDelete(language)}>
                            <Delete />
                          </IconButton>
                        )}
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            );
          } else {
            return null;
          }
        })}
      </Box>
      <Button
        sx={{ mt: 2, minWidth: "30%", alignSelf: "center", width: "auto" }}
        variant="contained"
        color="primary"
        disableRipple
        onClick={onAdd}
        data-cy="add-language-button"
      >
        {t("profilePage.language.addButton")}
      </Button>
    </Box>
  );
};

interface EmptyStateProps {
  onAdd: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ onAdd }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h6">{t("profilePage.language.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.language.emptyStateMessage")}
      </Typography>
      <Button variant="contained" color="primary" onClick={onAdd} disableRipple data-cy="add-language-button">
        {t("profilePage.language.addButton")}
      </Button>
    </Box>
  );
};

export default LanguagesComponent;
