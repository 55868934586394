import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Fab, CircularProgress, useTheme, useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ArrowBack } from "@mui/icons-material";
import {
  getApplicationDocumentThumbnails,
  getCoverLetterPreview,
  updateApplicationParameters,
  updateCoverLetter,
} from "../application/ApplicationRepository";
import { useNavigate, useParams } from "react-router-dom";
import {
  ApplicationDetails,
  updateApplication,
  updateCoverLetterThumbnail,
  updateResumeThumbnail,
} from "../../reducers/applicationDetailsPageSlice";
import ResumePreviewModal from "./modals/ResumePreviewModal";
import CoverLetterPreviewModal from "./modals/CoverLetterPreviewModal";
import { useTranslation } from "react-i18next";
import { SendApplicationModule } from "./modules/SendApplicationModule";
import { DRAWER_WIDTH } from "../../components/ApplicantAppBar";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { APPLICATION_LIST_ROUTE, APPLICATION_ROUTE } from "../../Routes";
import { ApplicationSummaryModule } from "./modules/ApplicationSummaryModule";
import ApplicationTemplateStyle from "../../types/ApplicationTemplateStyle";
import ApplicationTone from "../../types/ApplicationTone";
import CombinedEditModal from "./modals/CombinedEditModal";
import ApplicationLanguage, { SUPPORTED_LANGUAGES } from "../../types/ApplicationLanguage";
import DocumentPreviews from "./DocumentPreviews";

const maxWidth = 800;
const templates = [ApplicationTemplateStyle.Modern, ApplicationTemplateStyle.Classic, ApplicationTemplateStyle.Sharp];

const ApplicationDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [isUpdating, setIsUpdating] = useState(false);
  const [coverLetterContent, setCoverLetterContent] = useState("");
  const [isPreviewResumeModalOpen, setIsPreviewResumeModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPreviewCoverLetterModalOpen, setIsPreviewCoverLetterModalOpen] = useState(false);
  const [refreshCoverLetterPreview, setRefreshCoverLetterPreview] = useState(0);

  const hasFetchedThumbnails = useRef(false);
  const hasFetchedCoverLetter = useRef(false);
  const [isCoverLetterLoading, setIsCoverLetterLoading] = useState(false);

  const defaultLanguage = useMemo(
    () => ({ code: "default", name: t("applicationDetails.page.changeApplicationOriginalLanguage") }),
    [t],
  );
  const availableLanguages = useMemo(() => [defaultLanguage, ...SUPPORTED_LANGUAGES], [defaultLanguage]);

  const [currentTemplate, setCurrentTemplate] = useState<ApplicationTemplateStyle>(ApplicationTemplateStyle.Classic);
  const [currentLanguage, setCurrentLanguage] = useState<ApplicationLanguage>(availableLanguages[0]);

  const applicationDetails: ApplicationDetails | undefined = useAppSelector((state) =>
    id ? state.applicationDetailsPage.applicationsMap[id] : undefined,
  );

  useEffect(() => {
    if (!applicationDetails?.application) return;

    setCurrentTemplate(applicationDetails.application.templateStyle || ApplicationTemplateStyle.Classic);
    setCurrentLanguage(
      availableLanguages.find((lang) => lang.code === applicationDetails.application.language) || availableLanguages[0],
    );
  }, [applicationDetails, availableLanguages]);

  const updateApplicationSettings = async ({
    newTemplate,
    newLanguage,
    newTones,
  }: {
    newTemplate?: ApplicationTemplateStyle;
    newLanguage?: ApplicationLanguage;
    newTones?: ApplicationTone[];
  }) => {
    if (!id || !applicationDetails) return;
    setIsUpdating(true);

    const updatedTemplate = newTemplate ?? currentTemplate;
    const updatedLanguage = newLanguage ?? currentLanguage;
    const updatedTones = newTones ?? applicationDetails.application.tones;

    try {
      const applicationResponse = await updateApplicationParameters({
        applicationId: id,
        language: updatedLanguage.code,
        templateStyle: updatedTemplate,
        tones: updatedTones,
      });

      dispatch(updateApplication(applicationResponse.application));
      dispatch(updateResumeThumbnail({ applicationId: id, resumeThumbnail: applicationResponse.resumeThumbnail }));
      if (applicationResponse.coverLetterThumbnail) {
        dispatch(
          updateCoverLetterThumbnail({
            applicationId: id,
            coverLetterThumbnail: applicationResponse.coverLetterThumbnail,
          }),
        );
      }

      dispatch(showSnackbar({ message: t("applicationDetails.page.settingsChangeSuccess"), severity: "success" }));
    } catch (error) {
      console.error("Error updating application settings:", error);
      dispatch(showSnackbar({ message: t("applicationDetails.page.settingsChangeError"), severity: "error" }));
    } finally {
      setIsUpdating(false);
    }
  };

  const onTemplateChange = async (newTemplate: ApplicationTemplateStyle) => {
    setCurrentTemplate(newTemplate);
    await updateApplicationSettings({ newTemplate });
  };

  const onLanguageChange = async (newLanguage: ApplicationLanguage) => {
    setCurrentLanguage(newLanguage);
    await updateApplicationSettings({ newLanguage });
    if (applicationDetails?.application.coverLetterId) {
      try {
        const coverLetterResponse = await getCoverLetterPreview(applicationDetails.application.coverLetterId);
        if (coverLetterResponse && coverLetterResponse.letterContent) {
          setCoverLetterContent(coverLetterResponse.letterContent);
        } else {
          setCoverLetterContent("");
        }
      } catch (error) {
        console.error("Error fetching updated cover letter:", error);
        dispatch(showSnackbar({ message: t("applicationDetails.page.loadingDocumentsError"), severity: "error" }));
      }
    }
  };

  useEffect(() => {
    if (!id || !applicationDetails) return;

    if (!hasFetchedThumbnails.current) {
      hasFetchedThumbnails.current = true;

      const fetchThumbnails = async () => {
        try {
          const thumbnailResponse = await getApplicationDocumentThumbnails(id);
          dispatch(updateResumeThumbnail({ applicationId: id, resumeThumbnail: thumbnailResponse.resumeThumbnail }));
          dispatch(
            updateCoverLetterThumbnail({
              applicationId: id,
              coverLetterThumbnail: thumbnailResponse.coverLetterThumbnail,
            }),
          );
        } catch (error) {
          console.error("Error fetching thumbnails:", error);
          dispatch(showSnackbar({ message: t("applicationDetails.page.loadingDocumentsError"), severity: "error" }));
        }
      };

      fetchThumbnails();
    }
  }, [id, applicationDetails, dispatch, t]);

  useEffect(() => {
    if (!id || !applicationDetails) return;

    if (!applicationDetails.application.coverLetterId) return;

    if (hasFetchedCoverLetter.current) return;

    hasFetchedCoverLetter.current = true;
    setIsCoverLetterLoading(true);

    const fetchCoverLetter = async () => {
      try {
        const coverLetterResponse = await getCoverLetterPreview(applicationDetails.application.coverLetterId);
        if (coverLetterResponse && coverLetterResponse.letterContent) {
          setCoverLetterContent(coverLetterResponse.letterContent);
        } else {
          console.warn("Cover letter content is empty or undefined.");
          setCoverLetterContent("");
        }
      } catch (error) {
        console.error("Error fetching cover letter:", error);
        dispatch(showSnackbar({ message: t("applicationDetails.page.loadingDocumentsError"), severity: "error" }));
      } finally {
        setIsCoverLetterLoading(false);
      }
    };

    fetchCoverLetter();
  }, [id, applicationDetails, dispatch, t]);

  const handleSaveCoverLetter = async (
    content: string,
    language: ApplicationLanguage,
    template: ApplicationTemplateStyle,
    tone: ApplicationTone[],
  ) => {
    if (!id || !applicationDetails) return;

    const coverLetterId = applicationDetails.application.coverLetterId;

    if (!coverLetterId) {
      console.error("Cover Letter ID is undefined.");
      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.errorUpdating"),
          severity: "error",
        }),
      );
      return;
    }

    if (!content.trim()) {
      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.emptyContentError"),
          severity: "error",
        }),
      );
      return;
    }

    setIsUpdating(true);

    try {
      await updateCoverLetter(coverLetterId, content);
      setCoverLetterContent(content);

      await updateApplicationSettings({ newLanguage: language, newTemplate: template, newTones: tone });

      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.successUpdating"),
          severity: "success",
        }),
      );
      setIsEditModalOpen(false);
      setRefreshCoverLetterPreview((prev) => prev + 1);
    } catch (error) {
      console.error("Error saving cover letter and settings:", error);
      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.errorUpdating"),
          severity: "error",
        }),
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const handleBackPressed = () => {
    if (applicationDetails?.openedFromApplicationsList) {
      navigate(APPLICATION_LIST_ROUTE.path);
    } else {
      navigate(APPLICATION_ROUTE.path);
    }
  };

  const handleCoverLetterEdit = () => {
    const coverLetterId = applicationDetails?.application.coverLetterId;
    if (!coverLetterId) {
      dispatch(
        showSnackbar({ message: t("applicationDetails.coverLetterPreviewModal.errorLoading"), severity: "error" }),
      );
      return;
    }

    if (isCoverLetterLoading) {
      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.loadingContent"),
          severity: "info",
        }),
      );
      return;
    }

    if (!coverLetterContent) {
      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.contentUnavailable"),
          severity: "error",
        }),
      );
      return;
    }

    setIsEditModalOpen(true);
  };

  const handleCoverLetterPreview = () => setIsPreviewCoverLetterModalOpen(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ position: "relative" }}>
      {isUpdating && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 100,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}

      <Fab
        color="primary"
        aria-label={t("applicationDetails.page.goBack")}
        sx={{
          position: "fixed",
          top: 16,
          left: DRAWER_WIDTH + 16,
        }}
        onClick={handleBackPressed}
      >
        <ArrowBack />
      </Fab>

      {applicationDetails && applicationDetails.application ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              minHeight: "100vh",
              py: 8,
            }}
          >
            <Box
              sx={{
                flexBasis: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                ml: isSmallScreen ? 0 : "auto",
                pl: "10%",
                pr: "10%",
              }}
            >
              <ApplicationSummaryModule application={applicationDetails.application} maxWidth={maxWidth} />

              <Box sx={{ width: "100%", maxWidth: maxWidth }} id="Documents">
                <DocumentPreviews
                  applicationDetails={applicationDetails}
                  onResumePreview={() => setIsPreviewResumeModalOpen(true)}
                  onCoverLetterPreview={handleCoverLetterPreview}
                  onCoverLetterEdit={handleCoverLetterEdit}
                  currentTemplate={currentTemplate}
                  availableTemplates={templates}
                  onTemplateChange={onTemplateChange}
                  currentLanguage={currentLanguage}
                  availableLanguages={availableLanguages}
                  onLanguageChange={onLanguageChange}
                />
              </Box>

              <Box sx={{ width: "100%", maxWidth: maxWidth }} id="Submit">
                <SendApplicationModule maxWidth={maxWidth} applicationDetails={applicationDetails} />
              </Box>
            </Box>
          </Box>

          <ResumePreviewModal
            isOpen={isPreviewResumeModalOpen}
            resumeId={applicationDetails.application.resumeId}
            applicationId={applicationDetails.application.id}
            handleClose={() => setIsPreviewResumeModalOpen(false)}
          />
          <CoverLetterPreviewModal
            isOpen={isPreviewCoverLetterModalOpen}
            coverLetterId={applicationDetails.application.coverLetterId}
            applicationId={applicationDetails.application.id}
            hasApplied={applicationDetails.application.hasApplied}
            handleClose={() => setIsPreviewCoverLetterModalOpen(false)}
            onEditCoverLetter={handleCoverLetterEdit}
            refreshKey={refreshCoverLetterPreview}
          />
          <CombinedEditModal
            isOpen={isEditModalOpen}
            initialContent={coverLetterContent}
            currentLanguage={currentLanguage}
            currentTemplate={currentTemplate}
            currentTones={applicationDetails.application.tones}
            onSave={handleSaveCoverLetter}
            onClose={() => setIsEditModalOpen(false)}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default ApplicationDetailsPage;
