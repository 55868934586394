import { JobAdListItem } from "../../types/JobAdListItem";
import { MaijaError } from "../../types/MaijaError";
import { ApplicationTemplateStyle } from "../../types/ApplicationTemplateStyle";
import axiosInstance from "../../utils/AxiosInstance";
import { Application } from "../../types/Application";
import ApplicationTone from "../../types/ApplicationTone";
import { AxiosError } from "axios";

async function createApplicationWithDescription(
  request: CreateApplicationWithDescriptionRequest,
): Promise<CreateApplicationResponse> {
  try {
    const response = await axiosInstance.post("/application", {
      workplace: request.workplace,
      role: request.role,
      style: request.style,
      work_description: request.description,
    });
    return {
      resumeThumbnail: response.data.resume,
      coverLetterThumbnail: response.data.cover_letter,
      application: response.data.application,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createApplicationWithLink(
  request: CreateApplicationWithLinkRequest,
): Promise<CreateApplicationResponse> {
  try {
    const response = await axiosInstance.post("/application_link", {
      link: request.link,
      style: request.style,
    });
    return {
      resumeThumbnail: response.data.resume,
      coverLetterThumbnail: response.data.cover_letter,
      application: response.data.application,
    };
  } catch (error) {
    console.error(error);
    let errorMessage = "An error occurred while processing your request.";
    let errorType = "general";
    if (error instanceof AxiosError && error.response?.status === 400) {
      errorMessage = "Invalid request. Could not get work ad information from link.";
      errorType = "invalid-request";
    }
    throw new MaijaError(errorMessage, errorType);
  }
}

export async function emailApplication(applicationId: string): Promise<Application> {
  try {
    const response = await axiosInstance.get(`/application_action?action=email&application_id=${applicationId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function downloadApplication(applicationId: string): Promise<DownloadApplicationResponse> {
  try {
    const response = await axiosInstance.get(`/application_action?action=download&application_id=${applicationId}`);
    return {
      resume: response.data.resume,
      coverLetter: response.data.cover_letter,
      application: response.data.application,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function markApplicationAsApplied(applicationId: string, hasApplied: boolean): Promise<Application> {
  try {
    const response = await axiosInstance.post(`/mark_applied`, {
      application_id: applicationId,
      has_applied: hasApplied,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteApplication(applicationId: string): Promise<void> {
  await axiosInstance.delete(`/application/${applicationId}`);
}

export async function getApplicationDocumentThumbnails(applicationId: string): Promise<{
  resumeThumbnail: string;
  coverLetterThumbnail: string;
}> {
  const response = await axiosInstance.get(`/application_document_thumbnails?application_id=${applicationId}`);
  return {
    resumeThumbnail: response.data.resume,
    coverLetterThumbnail: response.data.cover_letter,
  };
}

export async function getResumePreview(resumeId: string): Promise<string[]> {
  try {
    const response = await axiosInstance.get(`/resume_preview?resume_id=${resumeId}`);
    return response.data.resume_thumbnails;
  } catch (error) {
    console.error("Error fetching resume preview:", error);
    throw error;
  }
}

export async function getCoverLetterPreview(coverLetterId: string): Promise<{
  thumbnails: string[];
  letterContent: string;
}> {
  try {
    const response = await axiosInstance.get(`/cover_letter_preview?cover_letter_id=${coverLetterId}`);
    return {
      thumbnails: response.data.cover_letter_thumbnails,
      letterContent: response.data.letter_content,
    };
  } catch (error) {
    console.error("Error fetching cover letter preview:", error);
    throw error;
  }
}

export async function updateCoverLetter(
  coverLetterId: string,
  coverLetterContent: string,
): Promise<{
  thumbnails: string[];
  letterContent: string;
}> {
  try {
    const response = await axiosInstance.post(`/update_cover_letter`, {
      cover_letter_id: coverLetterId,
      cover_letter_content: coverLetterContent,
    });
    return {
      thumbnails: response.data.cover_letter_thumbnails,
      letterContent: response.data.letter_content,
    };
  } catch (error) {
    console.error("Error fetching cover letter preview:", error);
    throw error;
  }
}

export async function getJobSearchSuggestions(query: string): Promise<{
  suggestions: string[];
}> {
  try {
    const response = await axiosInstance.get(`/job_ad_search_suggestions`, {
      params: { query: query },
    });
    return {
      suggestions: response.data.suggestions,
    };
  } catch (error) {
    console.error("Error fetching suggestions:", error);
    throw error;
  }
}

export async function searchJobAds(params: JobSearchParams): Promise<JobSearchResponse> {
  try {
    const response = await axiosInstance.post(`/job_ad_search`, params);
    return response.data;
  } catch (error) {
    console.error("Error fetching job search results:", error);
    throw error;
  }
}

export async function createApplicationWithAd(
  request: CreateApplicationWithAdRequest,
): Promise<CreateApplicationResponse> {
  try {
    const response = await axiosInstance.post("/job_ad_apply", {
      adId: request.adId,
    });
    return {
      resumeThumbnail: response.data.resume,
      coverLetterThumbnail: response.data.cover_letter,
      application: response.data.application,
    };
  } catch (error) {
    console.error(error);
    let errorMessage = "An error occurred while processing your request.";
    let errorType = "general";
    if (error instanceof AxiosError && error.response?.status === 400) {
      errorMessage = "Invalid request. Could not get work ad information from ad.";
      errorType = "invalid-request";
    }
    throw new MaijaError(errorMessage, errorType);
  }
}

export async function updateApplicationParameters(
  request: UpdateApplicationRequest,
): Promise<CreateApplicationResponse> {
  try {
    const response = await axiosInstance.post(`/update_application_parameters`, {
      application_id: request.applicationId,
      language: request.language,
      template_style: request.templateStyle,
      tones: request.tones,
    });
    return {
      resumeThumbnail: response.data.resumeThumbnail,
      coverLetterThumbnail: response.data.coverLetterThumbnail,
      application: response.data.application,
    };
  } catch (error) {
    console.error("Error updating application:", error);
    throw error;
  }
}

export async function getJobSearchPreferences(): Promise<{
  jobSearchLocation: string[];
  firstPreferredJob: string | null;
  secondPreferredJob: string | null;
  thirdPreferredJob: string | null;
}> {
  try {
    const response = await axiosInstance.get("/support_info/job_search_preferences");
    return {
      jobSearchLocation: response.data.jobSearchLocation,
      firstPreferredJob: response.data.firstPreferredJob,
      secondPreferredJob: response.data.secondPreferredJob,
      thirdPreferredJob: response.data.thirdPreferredJob,
    };
  } catch (error) {
    console.error("Error fetching job search preferences:", error);
    throw error;
  }
}

export async function setJobSearchLocation(location: string[]): Promise<void> {
  try {
    await axiosInstance.post("/support_info/job_search_location", {
      jobSearchLocation: location,
    });
  } catch (error) {
    console.error("Error setting job search location:", error);
    throw error;
  }
}

interface JobSearchResponse {
  numberOfAds: number;
  ads: JobAdListItem[];
}

interface JobSearchParams {
  query?: string;
  regions?: string[];
  municipalities?: string[];
  occupationFields?: string[];
  occupationGroups?: string[];
  maxRecords: number;
  startIndex: number;
}

export type CreateApplicationWithDescriptionRequest = {
  workplace: string;
  role: string;
  style?: ApplicationTemplateStyle;
  description?: string;
};

export type CreateApplicationWithLinkRequest = {
  link: string;
  style?: ApplicationTemplateStyle;
};

export type CreateApplicationWithAdRequest = {
  adId: string;
};

export type CreateApplicationResponse = {
  application: Application;
  resumeThumbnail: string;
  coverLetterThumbnail: string;
};

export type DownloadApplicationResponse = {
  resume: string;
  coverLetter: string;
  application: Application;
};

export type UpdateApplicationRequest = {
  applicationId: string;
  language?: string;
  templateStyle?: ApplicationTemplateStyle;
  tones?: ApplicationTone[];
};

export default createApplicationWithDescription;
