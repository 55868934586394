import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box, Card, CardContent, useTheme } from "@mui/material";
import { forgotPassword } from "./ForgotPasswordRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../Routes";
import { validateEmail } from "../../utils/TextValidation";
import { useTranslation } from "react-i18next";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    if (!validateEmail(email)) {
      setIsLoading(false);
      dispatch(
        showSnackbar({
          message: t("forgottenPassword.invalidEmail"),
          severity: "error",
        }),
      );
      setEmail("");
      return;
    }
    forgotPassword(email)
      .catch((errorMessage) => {
        console.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
        setEmail("");
        dispatch(
          showSnackbar({
            message: t("forgottenPassword.emailSent"),
          }),
        );
      });
  };

  if (isLoading) {
    return <MaijaLoadingPage />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `linear-gradient(20deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
      }}
    >
      <Card>
        <CardContent>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("forgottenPassword.title")}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("forgottenPassword.emailLabel")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                  data-cy="email"
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                  onClick={handleSubmit}
                  data-cy="submit"
                >
                  {t("forgottenPassword.submit")}
                </Button>
                <Button
                  type="button"
                  fullWidth
                  variant="text"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => {
                    navigate(LOGIN_ROUTE.path);
                  }}
                  data-cy="back-to-login"
                >
                  {t("forgottenPassword.backToLogin")}
                </Button>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgotPasswordPage;
