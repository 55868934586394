import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardProps,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import { ApplicationDetails, updateApplication } from "../../../reducers/applicationDetailsPageSlice";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { markApplicationAsApplied } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { Email, OpenInNew, CheckCircle, Undo } from "@mui/icons-material";
import Lottie from "lottie-react";
import celebrateAnimation from "../../../assets/lottie/celebrate4.json";

export interface SendApplicationModuleProps extends CardProps {
  maxWidth: number;
  applicationDetails: ApplicationDetails;
}

export const SendApplicationModule: React.FC<SendApplicationModuleProps> = ({
  maxWidth,
  applicationDetails,
  ...cardProps
}) => {
  const application = applicationDetails.application;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isApplying, setIsApplying] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUndoDialogOpen, setIsUndoDialogOpen] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

  const handleMarkAsApplied = async () => {
    setIsApplying(true);
    try {
      const response = await markApplicationAsApplied(application.id, true);
      dispatch(updateApplication(response));
      dispatch(
        showSnackbar({
          message: t("applicationDetails.page.markAppliedSuccess"),
          severity: "success",
        }),
      );
      setShowSuccessAnimation(true);
    } catch (error) {
      console.error(error);
      dispatch(
        showSnackbar({
          message: t("applicationDetails.page.markAppliedError"),
          severity: "error",
        }),
      );
    } finally {
      setIsApplying(false);
      setIsDialogOpen(false);
    }
  };

  const handleMarkAsNotApplied = async () => {
    setIsApplying(true);
    try {
      const response = await markApplicationAsApplied(application.id, false);
      dispatch(updateApplication(response));
      dispatch(
        showSnackbar({
          message: t("applicationDetails.page.markNotAppliedSuccess"),
          severity: "success",
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(
        showSnackbar({
          message: t("applicationDetails.page.markNotAppliedError"),
          severity: "error",
        }),
      );
    } finally {
      setIsApplying(false);
      setIsUndoDialogOpen(false);
    }
  };

  return (
    <>
      {applicationDetails.application.hasApplied && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack direction="column" spacing={1} alignItems="center">
              <Tooltip title={t("applicationDetails.page.markAsNotApplied")} arrow>
                <IconButton
                  onClick={() => setIsUndoDialogOpen(true)}
                  sx={{
                    p: 0.5,
                    color: "text.primary",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
              <Typography variant="caption" color="text.secondary" align="center">
                {t("applicationDetails.page.undoAction")}
              </Typography>
            </Stack>
          </Box>
        </Box>
      )}
      {!applicationDetails.application.hasApplied && (
        <Card
          sx={{
            width: "100%",
            maxWidth: maxWidth,
            my: 6,
            pt: 2,
            pb: 4,
            px: 4,
            "& button": {
              px: isSmallScreen ? 5 : 10,
              py: isSmallScreen ? 1.5 : 3,
            },
            ...cardProps.sx,
          }}
        >
          <Typography variant="h6" sx={{ mb: 4 }}>
            {t("applicationDetails.page.readyToApply")}
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2, mt: 4 }}>
            1. {t("applicationDetails.page.applyInstructions")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              alignItems: isSmallScreen ? "center" : "flex-start",
              mb: 4,
            }}
          >
            {applicationDetails.application.applyEmail && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ mb: 2, mr: 2 }}
                startIcon={<Email />}
                onClick={() => {
                  const email = applicationDetails.application.applyEmail;
                  const role = applicationDetails.application.role;
                  const workplace = applicationDetails.application.workplace;
                  const subject = encodeURIComponent(t("applicationDetails.page.emailSubject", { role, workplace }));
                  const body = encodeURIComponent(t("applicationDetails.page.emailBody", { role, workplace }));
                  window.open(`mailto:${email}?subject=${subject}&body=${body}`, "_blank");
                }}
              >
                {t("applicationDetails.page.applyEmail")}
              </Button>
            )}
            {applicationDetails.application.applyUrl && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ mb: 2, mr: 2 }}
                startIcon={<OpenInNew />}
                onClick={() => window.open(applicationDetails.application.applyUrl, "_blank")}
              >
                {t("applicationDetails.page.applyWebsite")}
              </Button>
            )}
            {applicationDetails.application.adUrl && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ mb: 2, mr: 2 }}
                startIcon={<OpenInNew />}
                onClick={() => window.open(applicationDetails.application.adUrl, "_blank")}
              >
                {t("applicationDetails.page.adUrl")}
              </Button>
            )}
            {!applicationDetails.application.applyEmail &&
              !applicationDetails.application.applyUrl &&
              !applicationDetails.application.adUrl && (
                <Typography variant="body1" color="textSecondary">
                  {t("applicationDetails.page.noApplyInfo")}
                </Typography>
              )}
          </Box>
          <Typography variant="subtitle1" sx={{ mb: 2, mt: 4 }}>
            2. {t("applicationDetails.page.markAsAppliedInstructions")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckCircle />}
              disabled={isApplying}
              onClick={() => setIsDialogOpen(true)}
            >
              {t("applicationDetails.page.markAsApplied")}
            </Button>
          </Box>
        </Card>
      )}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        PaperProps={{
          sx: {
            marginLeft: 60,
          },
        }}
      >
        <DialogTitle>{t("applicationDetails.page.confirmMarkAsAppliedTitle")}</DialogTitle>
        <DialogContent>
          <Typography>{t("applicationDetails.page.confirmMarkAsAppliedMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            {t("generic.cancel")}
          </Button>
          <Button onClick={handleMarkAsApplied} color="secondary" variant="contained" disabled={isApplying}>
            {t("generic.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isUndoDialogOpen} onClose={() => setIsUndoDialogOpen(false)}>
        <DialogTitle>{t("applicationDetails.page.confirmMarkAsNotAppliedTitle")}</DialogTitle>
        <DialogContent>
          <Typography>{t("applicationDetails.page.confirmMarkAsNotAppliedMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUndoDialogOpen(false)} color="primary">
            {t("generic.cancel")}
          </Button>
          <Button onClick={handleMarkAsNotApplied} color="secondary" variant="contained" disabled={isApplying}>
            {t("generic.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      {showSuccessAnimation && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1300,
          }}
        >
          <Lottie
            animationData={celebrateAnimation}
            loop={false}
            autoplay={true}
            style={{ width: "100vh", height: "100vh" }}
            onComplete={() => setShowSuccessAnimation(false)}
          />
        </Box>
      )}
    </>
  );
};
