import React, { useCallback, FC } from "react";
import { Box, Typography, IconButton, Tooltip, useTheme, Grid, Paper, Divider } from "@mui/material";
import { GetApp, Email, Edit } from "@mui/icons-material";
import DocumentPreview from "./DocumentPreview";
import { ApplicationDetails } from "../../reducers/applicationDetailsPageSlice";
import { useTranslation } from "react-i18next";
import { downloadApplication, emailApplication } from "../application/ApplicationRepository";
import { downloadBase64File } from "../../utils/FileDownloadUtil";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import TemplateSelector from "./TemplateSelector";
import ApplicationTemplateStyle from "../../types/ApplicationTemplateStyle";
import ApplicationLanguage from "../../types/ApplicationLanguage";

interface DocumentPreviewsProps {
  applicationDetails: ApplicationDetails;
  onCoverLetterEdit: () => void;
  onCoverLetterPreview: () => void;
  onResumePreview: () => void;
  currentTemplate: ApplicationTemplateStyle;
  availableTemplates: ApplicationTemplateStyle[];
  onTemplateChange: (newTemplate: ApplicationTemplateStyle) => void;
  currentLanguage: ApplicationLanguage;
  availableLanguages: ApplicationLanguage[];
  onLanguageChange: (language: ApplicationLanguage) => void;
}

type DocumentType = "resume" | "coverLetter";

interface DocumentSectionProps {
  title: string;
  thumbnail: string;
  hasApplied: boolean;
  onPreview: () => void;
  onEdit?: () => void;
  documentType: DocumentType;
  handleDownload: (type: DocumentType) => void;
  handleEmail: (type: DocumentType) => void;
}

const DocumentSection: FC<DocumentSectionProps> = ({
  title,
  thumbnail,
  hasApplied,
  onPreview,
  onEdit,
  documentType,
  handleDownload,
  handleEmail,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs={12} md={6} sx={{ position: "relative" }}>
      <Typography variant="h6" sx={{ mb: 1 }} align="center">
        {title}
      </Typography>
      <Box
        sx={{
          position: "relative",
          backgroundColor: theme.palette.grey[50],
          p: 1,
          borderRadius: 1,
        }}
      >
        <DocumentPreview
          content={thumbnail}
          tooltipText={t("applicationDetails.page.clickToPreview")}
          onClick={onPreview}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 8,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: 1,
          }}
        >
          {onEdit && !hasApplied && (
            <Tooltip title={t("applicationDetails.page.editTooltip")}>
              <IconButton onClick={onEdit}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t("applicationDetails.page.downloadTooltip")}>
            <IconButton onClick={() => handleDownload(documentType)}>
              <GetApp />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("applicationDetails.page.emailTooltip")}>
            <IconButton onClick={() => handleEmail(documentType)}>
              <Email />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Grid>
  );
};

const DocumentPreviews: FC<DocumentPreviewsProps> = ({
  applicationDetails,
  onCoverLetterEdit,
  onCoverLetterPreview,
  onResumePreview,
  currentTemplate,
  availableTemplates,
  onTemplateChange,
  currentLanguage,
  availableLanguages,
  onLanguageChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleDownload = useCallback(
    async (documentType: DocumentType) => {
      try {
        const data = await downloadApplication(applicationDetails.application.id);
        if (data) {
          const fileContent = documentType === "resume" ? data.resume : data.coverLetter;
          downloadBase64File(
            fileContent,
            `${documentType === "resume" ? "Resume" : "Cover_Letter"}_${applicationDetails.application.workplace}`,
          );
        }
      } catch {
        dispatch(
          showSnackbar({
            message: t("applicationDetails.page.downloadingError"),
            severity: "error",
          }),
        );
      }
    },
    [applicationDetails.application.id, applicationDetails.application.workplace, dispatch, t],
  );

  const handleEmail = useCallback(async () => {
    try {
      await emailApplication(applicationDetails.application.id);
      dispatch(
        showSnackbar({
          message: t("applicationDetails.page.emailingSuccess"),
          severity: "success",
        }),
      );
    } catch {
      dispatch(
        showSnackbar({
          message: t("applicationDetails.page.emailingError"),
          severity: "error",
        }),
      );
    }
  }, [applicationDetails.application.id, dispatch, t]);

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        p: 3,
        borderRadius: 2,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      {!applicationDetails.application.hasApplied && (
        <>
          <Typography variant="h4" gutterBottom align="center">
            {t("applicationDetails.page.applicationReadyTitle")}
          </Typography>

          <Typography variant="body2" sx={{ mb: 4 }} align="center">
            {t("applicationDetails.page.youCanMakeChanges")}
          </Typography>
        </>
      )}

      <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
        <DocumentSection
          title={t("applicationDetails.page.resume", "Resume")}
          thumbnail={applicationDetails.resumeThumbnail || ""}
          hasApplied={applicationDetails.application.hasApplied}
          onPreview={onResumePreview}
          documentType="resume"
          handleDownload={handleDownload}
          handleEmail={handleEmail}
        />
        <DocumentSection
          title={t("applicationDetails.page.coverLetter", "Cover Letter")}
          thumbnail={applicationDetails.coverLetterThumbnail || ""}
          hasApplied={applicationDetails.application.hasApplied}
          onPreview={onCoverLetterPreview}
          onEdit={onCoverLetterEdit}
          documentType="coverLetter"
          handleDownload={handleDownload}
          handleEmail={handleEmail}
        />
        {!applicationDetails.application.hasApplied && (
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Divider sx={{ mb: 3 }} />
            <TemplateSelector
              currentTemplate={currentTemplate}
              availableTemplates={availableTemplates}
              onTemplateChange={onTemplateChange}
              currentLanguage={currentLanguage}
              availableLanguages={availableLanguages}
              onLanguageChange={onLanguageChange}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default DocumentPreviews;
