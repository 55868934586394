import React from "react";
import { Box, Typography, Select, MenuItem, useTheme } from "@mui/material";
import ApplicationTemplateStyle from "../../types/ApplicationTemplateStyle";
import { useTranslation } from "react-i18next";
import ApplicationLanguage from "../../types/ApplicationLanguage";
import Flag from "react-world-flags";

interface TemplateSelectorProps {
  currentTemplate: ApplicationTemplateStyle;
  availableTemplates: ApplicationTemplateStyle[];
  onTemplateChange: (template: ApplicationTemplateStyle) => void;
  currentLanguage: ApplicationLanguage;
  availableLanguages: ApplicationLanguage[];
  onLanguageChange: (language: ApplicationLanguage) => void;
}

const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  currentTemplate,
  availableTemplates,
  onTemplateChange,
  currentLanguage,
  availableLanguages,
  onLanguageChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        p: 2,
        minWidth: 150,
        width: "100%",
        maxWidth: 600,
        margin: "0 auto",
      }}
    >
      <Box sx={{ width: "48%" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {t("applicationDetails.page.changeApplicationLanguage")}
        </Typography>
        <Select
          value={currentLanguage.code}
          onChange={(event) => onLanguageChange(availableLanguages.find((lang) => lang.code === event.target.value)!)}
          fullWidth
        >
          {availableLanguages.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Flag code={language.flag} style={{ marginRight: 8 }} width={20} />
                {language.name}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ width: "48%" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {t("applicationDetails.page.changeApplicationTemplate")}
        </Typography>
        <Select
          value={currentTemplate}
          onChange={(event) => onTemplateChange(event.target.value as ApplicationTemplateStyle)}
          fullWidth
        >
          {availableTemplates.map((template) => (
            <MenuItem key={template} value={template}>
              {t(`applicationDetails.page.templates.${template}`)}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default TemplateSelector;
